const l = {
  "base.and": "og",
  "base.toc": "Vilkår og betingelser",
  "base.privacyPolicy": "Fortrolighedspolitik",
  "base.createAccount": "Opret konto",
  "base.signin": "Log ind",
  "base.signup": "Tilmeld",
  "base.logOut": "Log ud",
  "authentication.description": "Log ind eller opret en konto",
  "invitation.wrongEmailTitle": "Allerede logget ind",
  "invitation.wrongEmailText":
    "Det ser ud til, at du allerede er logget ind på en anden konto. Log venligst ud og prøv igen.",
  "invitation.invalidLinkTitle": "Ugyldig invitation",
  "invitation.invalidLinkText": "Invitationslinket er ugyldigt",
  "invitation.completeSignup": "Udfyld venligst tilmeldingen til ",
  "invitation.completeSignupDescription":
    "En bekræftelses-e-mail er blevet sendt til din adresse. Hvis du ikke kan finde den i din indbakke, skal du tjekke din spammappe.",
  "invitation.completeSignupButton": "Udfyld tilmelding",
  "invitation.mustSignupTitle": "Du er blevet inviteret til ",
  "invitation.signInToAccept":
    "Log venligst ind for at acceptere invitationen. I invitations-e-mailen, du har modtaget, finder du en midlertidig adgangskode.",
  "invitation.acceptTermsOnSignup":
    "Når du logger ind, accepterer du automatisk vores",
  "invitation.accept": "Log ind for at acceptere invitationen",
  "invitation.failed":
    "Failed to accept invitation. Please try to logout and login - or ask the administrator to invite you again.",
  "invitation.accepted": "Invitation accepteret",
  "invitation.emailNotVerified":
    "E-mail ikke verificeret. Verificer venligst din e-mail og prøv igen.",
  "country.venezuela": "Venezuela",
  "country.japan": "Japan",
  "country.algeria": "Algeriet",
  "country.france": "Frankrig",
  "country.spain": "Spanien",
  "country.china": "Kina",
  "country.usa": "USA",
  "country.england": "England",
  "country.brazil": "Brasilien",
  "country.italy": "ITALIEN",
  "country.denmark": "Danmark",
  "head.title": "Talkio AI | Øv sproget med AI",
  "landing.title1": "Øv ",
  "landing.title2": "Sprog ",
  "landing.title3": "med AI ",
  "landing.subtitle1":
    "Den ultimative sprogtræningsapp, der bruger AI-teknologi til at hjælpe dig med at forbedre dine mundtlige færdigheder.",
  "landing.button.freeTrial": "7 dage gratis",
  "landing.creditCardNotice":
    "Kreditkort kræves - kan annulleres når som helst",
  "landing.text.voiceControlTeam": "Fra teamet bag Voice Control til ChatGPT",
  "landing.text.trustedBy": "Betroet af mere end 500.000 brugere",
  "landing.card.title.advancedAI": "Bygget med avanceret AI-teknologi",
  "landing.card.text.advancedAI":
    "Talkio AI er bygget med avanceret AI-teknologi og gør det muligt for dig at interagere med AI gennem stemme for at træne dine mundtlige færdigheder.",
  "landing.card.title.multipleLanguages": "Understøtter flere sprog",
  "landing.card.text.multipleLanguages":
    "Vi tilbyder et bredt udvalg af sprog, herunder engelsk, kinesisk, fransk og flere andre sprog.",
  "landing.card.title.premiumVoice": "Premium stemmeteknologi",
  "landing.card.text.premiumVoice":
    "Talkio AI tilbyder premium stemmer og understøtter flere dialekter for de mest populære sprog. Med vores avancerede sprogteknologi kan du fordybe dig i autentiske samtaler og opnå færdigheder i de dialekter, der betyder mest for dig.",
  "landing.sectionTitle.meetTutors": "Mød AI-tutorerne",
  "landing.sectionText.moreThanTutors":
    "Talkio AI har mere end 400 tutorer med unikke kunstige personligheder klar til at hjælpe dig på din sprogrejse.",
  "landing.text.andManyMore": "Og mange flere",
  "landing.text.learnWithAITutors": "Lær med AI-tutorer",
  "landing.text.wonderedAboutTutor":
    "Har du nogensinde undret dig over, hvordan det ville være at have en personlig sprog tutor tilgængelig når som helst, hvor som helst? Hos Talkio AI gør vi denne drøm til virkelighed. Vores AI-tutorer er de perfekte ledsagere til at forbedre dine mundtlige færdigheder. Drevet af avanceret AI-teknologi efterligner de menneskelig interaktion og samtale og tilbyder en engagerende og effektiv oplevelse til sprogindlæring.",
  "landing.text.rememberLanguageLearning":
    "Husk, sprogindlæring er mere end blot grammatik og ordforråd; det handler om at udtrykke dig selv med selvtillid. Med Talkio AI's intelligente tutorer kan du tale, øve og nå dine sprog mål!",
  "landing.sectionTitle.featureSpotlight": "Funktioner i rampelyset",
  "landing.sectionText.featureSpotlight":
    "Navigér gennem vores suite af funktioner, designet til at forvandle din sprogindlæring til en engagerende rejse.",
  "landing.text.safeEnvironment": "Sikker og støttende atmosfære",
  "landing.text.supportiveEnvironment":
    "Vores app giver en sikker og støttende atmosfære til at øve at tale med selvtillid. Med Talkio AI kan du have meningsfulde samtaler med hjælp fra AI-teknologi, hvilket giver dig mulighed for at opnå flydende tale og udtrykke dig selv på det sprog, du lærer.",
  "landing.sectionTitle.insights": "Indsigter",
  "landing.sectionText.realStories":
    "Rigtige historier fra rigtige brugere af Talkio AI",
  "landing.sectionTitle.faq": "Ofte stillede spørgsmål",
  "landing.sectionText.faq": "Svar på ofte stillede spørgsmål om Talkio AI",
  "landing.sectionTitle.pricing": "Prissætning",
  "landing.sectionText.unlockPotential":
    "Lås din sproglige potentiale op med Talkio. Din rejse mod mestring starter nu.",
  "landing.sectionText.offersForPartners":
    "Vi tilbyder også planer for skoler og grupper",
  "landing.imageAlt.landing": "Landing",
  "landing.imageAlt.laurelLeft": "Laurbær venstre",
  "landing.imageAlt.laurelRight": "Laurbær højre",
  "landing.imageAlt.speakingWithAI": "Kvinde der taler med AI",
  "testimonial.text1":
    "Jeg har personligt brugt Talkio AI til at forbedre mit spansk, og lad mig sige dig, det er en fantastisk løsning.",
  "testimonial.text2":
    "Talkio AI er et meget imponerende værktøj og bør inkluderes i enhver sproglærers værktøjssamling.",
  "testimonial.text3":
    "Dette værktøj Talkio AI blæser mig væk til sprogindlæring.",
  "testimonial.text4": "Talkio AI - det er et fantastisk produkt.",
  "feature.lifeLikeConversations": "Livagtige samtaler med AI-tutorer",
  "feature.pronunciationTool": "Udtalesesværktøj",
  "feature.detailedFeedback": "Detaljeret feedback på dine talefærdigheder",
  "feature.interactiveWordbook": "Interaktiv ordbog",
  "feature.supportsLanguages": "Understøtter over 40 sprog",
  "landing.text.voiceConversations": "Stemmesamtaler",
  "landing.text.voiceConversationsDescription":
    "Har engagerende stemmesamtaler med vores AI-tutorer",
  "landing.text.pronunciation": "Udtale",
  "landing.text.pronunciationDescription":
    "Vurder og øv din udtale med ord-til-ord feedback",
  "landing.text.feedback": "Feedback",
  "landing.text.feedbackDescription":
    "Få øjeblikkelig feedback på dine sprogfærdigheder og tips til, hvordan du kan forbedre",
  "landing.text.multipleLanguages": "Flere sprog og dialekter",
  "landing.text.multipleLanguagesDescription":
    "Vælg mellem 134 sprog og dialekter til at øve dine talefærdigheder",
  "landing.text.progress": "Fremskridt",
  "landing.text.progressDescription":
    "Hold styr på dine fremskridt og få ugentlige rapporter om din sprogindlæringsrejse",
  "landing.text.wordbook": "Ordbog",
  "landing.text.wordbookDescription": "Gem og genbesøg ord lært under træning",
  "landing.text.streaks": "Stræk",
  "landing.text.streaksDescription":
    "Følg dine fremskridt med stræk for at opbygge vanen med at lære",
  "landing.text.crosstalk": "Crosstalk",
  "landing.text.crosstalkDescription":
    "Kommuniker med tutoren på både dit modersmål og det sprog, du lærer",
  "landing.text.translations": "Oversættelser",
  "landing.text.translationsDescription":
    "Øjeblikkelige oversættelser til at støtte dig i dine samtaler",
  "landing.text.featureSpotlight": "Funktioner i rampelyset",
  "landing.text.featureSpotlightDescription":
    "Navigér gennem vores suite af funktioner, designet til at forvandle din sprogindlæring til en engagerende rejse",
  "feature.practiceSpeaking.title": "Øv tale",
  "feature.practiceSpeaking.bullet1": "Livagtige samtaler med en AI-tutor",
  "feature.practiceSpeaking.bullet2": "Stemmesysteminteraktion",
  "feature.practiceSpeaking.bullet3": "Samtalehjælp",
  "feature.practiceSpeaking.bullet4": "Gentag og prøv igen",
  "feature.worldOfLanguages.title": "En verden af sprog",
  "feature.worldOfLanguages.bullet1": "Understøtter over 40 sprog",
  "feature.worldOfLanguages.bullet2": "Øjeblikkelige oversættelser",
  "feature.worldOfLanguages.bullet3": "Flersproget talegenkendelse",
  "feature.worldOfLanguages.bullet4": "Romanisering af skriftsprog",
  "feature.actionableFeedback.title": "Handlingsbare feedback",
  "feature.actionableFeedback.bullet1": "Sætning-scoring",
  "feature.actionableFeedback.bullet2": "Forbedringsstrategier",
  "feature.actionableFeedback.bullet3": "AI-forslag",
  "feature.actionableFeedback.bullet4": "Taledetaljeringsscore",
  "feature.pronunciationPractice.title": "Udtalepraksis",
  "feature.pronunciationPractice.bullet1": "Ord-til-ord score",
  "feature.pronunciationPractice.bullet2": "Udtalevurdering",
  "feature.pronunciationPractice.bullet3":
    "Nøjagtighed, fuldstændighed og flydende",
  "feature.pronunciationPractice.bullet4": "Gentag og prøv igen",
  "feature.interactiveWordbook.title": "Interaktiv ordbog",
  "feature.interactiveWordbook.bullet1": "Hold styr på de ord, du lærer",
  "feature.interactiveWordbook.bullet2":
    "Forstærket memorisering via tutorrepetition",
  "feature.interactiveWordbook.bullet3": "Oversættelse & læs højt",
  "feature.interactiveWordbook.bullet4": "Sætningseksempler",
  "feature.button.startTrial": "Start din gratis prøveperiode",
  "faq.question1": "Er der en gratis plan?",
  "faq.answer1":
    "Nej, alle planer er betalte. Der er dog en 7-dages gratis prøveperiode, der kan annulleres når som helst. Uden betingelser.",
  "faq.question2": "Hvem står bag Talkio AI?",
  "faq.answer2.part1": "Talkio AI er et produkt af",
  "faq.answer2.part2":
    "noteret i Den Europæiske Union. Aidia ApS overholder den generelle databeskyttelsesforordning (GDPR) for at levere en sikker og tryg oplevelse for alle brugere.",
  "faq.question3": "Er Talkio AI egnet til begyndere?",
  "faq.answer3":
    "Talkio AI er bedst egnet til dem med en grundlæggende viden om et sprog, som har til hensigt at forbedre deres mundtlige færdigheder. For begyndere har vi udviklet en introduktionsguide til at komme i gang. Denne guide dækker grundlæggende aspekter af dit valgte sprog og forbereder dig til at få fuldt udbytte af Talkio AI. Det er gratis og et fantastisk værktøj for lærere, der starter deres rejse. Tjek guiden ud og begynder at forbedre dine sprogfærdigheder effektivt.",
  "faq.button.gettingStartedGuide": "Prøv den GRATIS kom godt i gang-guide",
  "faq.question4": "Er betaling sikker?",
  "faq.answer4":
    "Ja, vi bruger Stripe til finansiel behandling. Vi gemmer ikke nogen af ​​dine kreditkortoplysninger.",
  "faq.question5": "Hvor er mine data gemt?",
  "faq.answer5":
    "Dine data gemmes på servere i Den Europæiske Union: Microsoft (stemme og data), Auth0 (autentifikation), Stripe (betalinger). Desuden bruger vi tjenester fra OpenAI, Anthropic og Groq til at drive AI. Disse data behandles i USA.",
  "faq.question6": "Kan jeg få en tilbagebetaling?",
  "faq.answer6":
    "Den gratis prøveperiode kan annulleres når som helst; men hvis du glemmer at annullere prøveperioden, kan vi refundere dig inden for 14 dage, forudsat at du ikke har brugt servicen efter prøveperioden sluttede. Kontakt os venligst på support@talkio.ai",
  "faq.question7": "Hvilke betalingsmetoder accepterer I?",
  "faq.answer7":
    "For abonnementer accepterer vi alle større kreditkort og Paypal. For engangskøb accepterer vi også WeChat, Przelewy24, Giropay, Alipay og mere. Tilgængelighed afhænger af din placering.",
  "faq.question8": "Har Talkio en mobilapp?",
  "faq.answer8":
    "Talkio er en progressiv webapp, hvilket betyder, at du kan installere den fra browseren uden at gå til App Store eller Google Play. På Android, mens du bruger Talkio i din browser, finder du en installationsknap efter tilmelding. På iOS åbner du Talkio i Safari, trykker på delingsikonet og vælger 'Tilføj til startskærm' for at installere den ligesom en almindelig app på din telefon.",
  "faq.question9": "Hvilke browsere kan jeg bruge?",
  "faq.answer9":
    "Talkio AI fungerer i alle moderne browsere, inklusive Chrome, Firefox, Safari og Edge. Det fungerer også på mobile browsere på iPhone og Android.",
  "faq.question10": "Hvilke AI-modeller bruger I?",
  "faq.answer10":
    "Talkio AI bruger en blanding af forskellige AI-modeller fra OpenAI, Anthropic og Meta. Hver model har en specifik opgave, som de excellerer i, og sammen skaber de en kraftfuld AI-tutor.",
  "faq.question11":
    "Hvordan adskiller Talkio AI sig fra Voice Control for ChatGPT?",
  "faq.answer11":
    "Talkio AI tilbyder en sammenhængende oplevelse med ChatGPT tilpasset til sprogindlæring. Det tilbyder også flere funktioner, herunder udtalelsesvurdering, premium stemmeteknologier, flersproget support, kuraterede træningsmaterialer, integreret feedback og oversættelser.",
  "faq.question12": "Hvordan kan jeg kontakte jer?",
  "faq.answer12":
    "Du er meget velkommen til at skrive en e-mail til hello@talkio.ai",
  "layout.serviceBar.text":
    "Grundlagt i Danmark. Vi respekterer din fortrolighed.",
  "layout.serviceBar.community":
    "Deltag i et verdensomspændende fællesskab af sprogstuderende",
  "layout.nav.pricing": "Prissætning",
  "layout.nav.faq": "FAQ",
  "layout.nav.languages": "Sprog",
  "layout.nav.blog": "Blog",
  "layout.nav.schools": "For skoler",
  "layout.nav.teams": "For teams",
  "layout.nav.affiliateProgram": "Affiliate program",
  "layout.nav.signIn": "Log Ind",
  "layout.nav.goToApp": "Gå til app",
  "layout.nav.menu": "Menu",
  "layout.cta.description":
    "Den ultimative sprogtræningsapp, der bruger AI-teknologi til at hjælpe dig med at forbedre dine mundtlige færdigheder.",
  "layout.cta.button": "Prøv Talkio AI",
  "layout.footer.pages": "Sider",
  "layout.footer.learningHub": "Læringshub",
  "layout.footer.blog": "Blog",
  "layout.footer.about": "Om",
  "layout.footer.affiliateProgram": "Affiliate program",
  "layout.footer.schools": "Talkio for Skoler",
  "layout.footer.teams": "Talkio for Teams",
  "layout.footer.languageGuide": "Gratis sprogguide til begyndere",
  "layout.footer.termsConditions": "Vilkår og betingelser",
  "layout.footer.privacyPolicy": "Fortrolighedspolitik",
  "layout.footer.logout": "Log ud",
  "layout.footer.login": "Log ind",
  "layout.footer.blogPosts": "Blog indlæg",
  "layout.footer.blogPost1": "Ny funktion på Talkio AI: Interaktiv sprogguide",
  "layout.footer.blogPost2":
    "Talkio integrerer tre nye brugerdefinerede GPT'er",
  "layout.footer.blogPost3":
    "Forbedr dine IELTS taleevner med Talkio AI: En trin-for-trin guide",
  "layout.footer.blogPost4": "3 effektive måder at øve mundtlig engelsk på",
  "layout.footer.blogPost5": "Få succes med dine engelsksproglige tests",
  "layout.footer.blogPost6":
    "At mestre TOEFL tale med Talkio AI: Din essentielle guide",
  "layout.footer.contact": "Kontakt",
  "layout.footer.rights": "Alle rettigheder forbeholdes.",
  "layout.footer.illustrationCredits":
    "Illustrationer på forside af vectorjuice",
  "pricing.description.perMonthBilledOnce": "pr. måned - faktureres én gang",
  "pricing.description.perMonthBilledQuarterly":
    "pr. måned - faktureres kvartalsvis",
  "pricing.description.perMonthBilledYearly": "pr. måned - faktureres årligt",
  "pricing.description.perMonth": "pr. måned",
  "pricing.button.buyNow": "Køb nu med 7-dages pengene-tilbage-garanti",
  "pricing.button.startFreeTrial": "Start din gratis prøveperiode",
  "pricing.features.refund":
    "7-dages fuld tilbagebetaling - kan annulleres når som helst",
  "pricing.features.freeTrial":
    "7-dages gratis prøveperiode - kan annulleres når som helst",
  "pricing.features.supportsLanguages": "Understøtter over 40 sprog",
  "pricing.features.voiceConversations": "Livagtige stemmesamtaler",
  "pricing.features.instantFeedback": "Øjeblikkelig feedback",
  "pricing.features.weeklyProgressReport": "Ugentlig fremskridtsrapport",
  "pricing.features.pronunciationPractice": "Udtalepraksis",
  "pricing.features.interactiveWordbook": "Interaktiv ordbog",
  "pricing.features.speechRecognition": "Flersproget talegenkendelse",
  "pricing.features.extraFeatures": "Rollespil, emner, quizzer og mere!",
};

export default l;
